<template>
    <transition name="fade">
        <div>
            <div class="modal fade custom-fields show in" style="display: block">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content" v-on-click-away="closeModal">
                        <form action="">
                            <div class="modal-header">
                                <h4 class="modal-title text-capitalize">{{ $t("Assign assessment") }}</h4>
                                <button type="button" class="close" @click="closeModal">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label for="selected_assessment"> {{ $t(`Select assessment`) }}*</label>
                                            <a href="#" v-if="showAssignedAssignmentLink" @click="showAssignedAssignment" class="assigned-assessment-button">{{ $t(`Show Assigned Assessment`) }}</a>
                                            <multiselect
                                                    id="selected_assessment"
                                                    v-model="selected_assessment"
                                                    :options="assessments"
                                                    :placeholder="$t('Select assessment')"
                                                    :allow-empty="false"
                                                    deselect-label=""
                                                    track-by="id"
                                                    label="name"
                                                    name="name"
                                            ></multiselect>
                                            <error-message :message="$t(errors.assessment)"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label id="expire_date">{{ $t(`Expire date`) }}*</label>

                                            <date-picker
                                                    v-model="form.expire_date"
                                                    :placeholder="$t('mm/dd/yyyy')"
                                                    autocomplete="off"
                                                    :config="datePickerOptions"
                                            ></date-picker>

                                            <error-message :message="$t(errors.expire_date)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <div class="w-100 d-flex justify-content-between align-items-top" style="gap: 10px">
                                    <span class="text" style="font-size: 0.75rem;">{{ $t('Note: Candidate will be notified by message.') }}</span>
                                    <submit-button :click="saveData" class="info-button ml-auto text-capitalize" :loading="isLoading" style="flex:0 0 auto">
                                        {{ $t("Assign assessment") }}
                                    </submit-button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-backdrop fade show"></div>
        </div>
    </transition>
</template>
<script>
import Multiselect from 'vue-multiselect';
import _ from 'lodash';
import ErrorMessage from "../../common/ErrorMessage";
import client from "../../../app/api/Client";
import moment from "moment";
import {datePickerIcons} from "../../../config/options";
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';


export default {
    props: [
        'selectedApplicants', 'job'
    ],
    components: {
        ErrorMessage,
        datePicker,
        Multiselect
    },
    data() {
        return {
            form: {
                applicants: [],
                expire_date: '',
                assessment_id: null
            },
            errors: {},
            selected_assessment: null,
            assessments: [],
            isLoading: false,
            assessmentEdit: null,
            showAssignedAssignmentLink: false
        }
    },
    computed: {
        datePickerOptions() {
            return {
                format: 'MM/DD/YYYY',
                minDate: moment().format('MM/DD/YYYY'),
                icons: datePickerIcons,
                useCurrent: false
            }
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal');
        },
        showAssignedAssignment() {
            this.$emit('showAssignedAssignment');
        },
        getFormData() {
            client().get(`company/assessments`, {params: {
                    applicants: this.selectedApplicants
                }}).then((response) => {
                this.assessments = response.data.data;
            });
        },
        async saveData() {
            if (!this.isValidated()) {
                return false;
            }
            this.form.applicants = this.selectedApplicants;
            this.form.assessment_id = this.selected_assessment.id;
            this.isLoading = true;
            try {
                let {data} = await client().post(`/job/${this.job}/assign-assessment`, this.form);
                if (data.status === 'SUCCESS') {
                    this.$emit('assessmentUpdated');
                } else {
                    this.$toast.error(this.$t(data.message));
                }
            } catch (err) {
                if (err.response.status === 422) {
                    this.errors = _helper.serializeValidationMessage(err.response.data.message);
                    this.$toast.error(this.$t('Oops! Please check your input'));
                } else {
                    this.$toast.error(this.$t(err.response.data.message));
                }
            }
            this.isLoading = false;
            this.closeModal();
        },
        isValidated() {
            this.errors = {};
            if (!this.selected_assessment) {
                this.errors.assessment = `Please select an assessment.`;
                return false;
            }
            if (!this.form.expire_date.length > 0 && !_.isDate(this.form.expire_date)) {
                this.errors.expire_date = `Expire date is required.`;
                return false;
            }
            if (!this.form.expire_date.length > 0 && !_.isDate(this.form.expire_date)) {
                this.errors.expire_date = `Expire date is required.`;
                return false;
            }
            return true;
        },
        async getEvaluation() {
            if(typeof this.$route.params.id != 'undefined') {
                let {data: {data}} = await client().get(`job/applicant/${this.$route.params.id}/evaluation`);
                let assessments = data.assessment_data;
                if (assessments.length > 0) {
                    this.showAssignedAssignmentLink  = true;
                }
            }
        },
    },
    async mounted() {
        this.getFormData();

        await this.getEvaluation();


    }
}
</script>

<style scoped>
.assigned-assessment-button {
    float: right !important;
    color: rgb(89, 125, 252);
    font-size: 0.875rem;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.modal-body {
    overflow-y: unset !important;
}
</style>
